import { PAGE } from '~/constants/router'
import { IAccountFeature } from '~/types/menu.type'

export const accountFeatures: IAccountFeature[] = [
  {
    icon: '/assets/images/common/account/icon-account.svg',
    text: 'Tài Khoản',
    url: PAGE.ACCOUNT_PROFILE,
    mapUrl: [PAGE.ACCOUNT_PROFILE, PAGE.ACCOUNT_PROFILE + '?tab=password', PAGE.ACCOUNT_BANK, PAGE.ACCOUNT_PROFIT_TODAY]
  },
  {
    icon: '/assets/images/common/account/icon-history.svg',
    text: 'Lịch Sử Giao Dịch',
    url: PAGE.ACCOUNT_HISTORY_TRANSACTION,
    mapUrl: [PAGE.ACCOUNT_HISTORY_TRANSACTION]
  },
  {
    icon: '/assets/images/common/account/icon-betting.svg',
    text: 'Lịch sử cá cược',
    url: PAGE.ACCOUNT_HISTORY_BETTING,
    mapUrl: [PAGE.ACCOUNT_HISTORY_BETTING]
  },
  {
    icon: '/assets/images/common/account/icon-logout.svg',
    text: 'Đăng xuất',
    url: 'logout'
  }
]
