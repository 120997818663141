<template>
  <footer class="footer" :class="{ 'footer-custom': isCollapse }">
    <div class="footer-top">
      <div class="container" />
    </div>
    <div class="footer-bottom">
      <div class="container">
        <div class="footer-infor">
          <div class="footer-infor__left">
            <h2>
              <img
                src="/assets/images/logo.svg"
                alt=""
                width="122"
                height="32"
                class="footer-logo"
                data-v-f1ec58ce=""
              />
            </h2>
            <p>
              {{ $config.public.BRANCH_NAME }} - Nhà cái đẳng cấp Châu Âu. {{ $config.public.BRANCH_NAME }} đem đến cho
              khách hàng dịch vụ cá cược trực tuyến hàng đầu với hàng trăm sản phẩm cược hấp dẫn như: Thể Thao, Thể Thao
              Ảo, Casino, Number Game...
            </p>
          </div>
          <div class="footer-infor__center footer-partner">
            <p class="footer-title">PHƯƠNG THỨC THANH TOÁN</p>
            <ul class="footer-payment-method">
              <li v-for="(item, index) in footer.footerPaymentMethods" :key="index" class="footer-payment-method__item">
                <base-img :src="item.imgSrc" alt="icon" width="28" height="28" />
                <span>{{ item.text }}</span>
              </li>
            </ul>
          </div>
          <div class="footer-infor__right">
            <p class="footer-title">Liên hệ hỗ trợ</p>
            <div class="footer-contact">
              <div class="footer-contact--row">
                <p v-for="(contact, index) in contacts" :key="index" class="footer-contact--item" @click="handleClick(contact)">
                  <base-img :src="contact.image" :alt="contact.display_name" class="icon" width="24" height="24" />
                  <a :href="contact.url">{{ contact.content }}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-provider_list">
          <div class="ticker">
            <div class="list-provider">
              <div class="list-provider__wrapper">
                <nuxt-link v-for="(item, index) in footer.footerPartner" :key="index" :to="item.link" class="provider-item">
                  <base-img v-if="!item.href" :src="item.imgSrc" alt="logo partner" />
                </nuxt-link>
              </div>
            </div>
            <div class="list-provider">
              <div class="list-provider__wrapper">
                <nuxt-link v-for="(item, index) in footer.footerPartner" :key="index" :to="item.link" class="provider-item">
                  <base-img v-if="!item.href" :src="item.imgSrc" alt="logo partner" />
                </nuxt-link>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-copyright">
          <p>Copyright © 2021 {{ $config.public.BRANCH_NAME }}</p>
          <nuxt-link v-for="(item, index) in footer.footerMenu" :key="index" :to="item.link" class="footer-link">
            {{ item.text }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { footer } from '@/resources/footer'
import BaseImg from '~/components/common/base-img.vue'
import { useContact } from '~/composables/home/useContact'
const { $config } = useNuxtApp()
const { fetchBranchContact, contacts } = useContact()

onMounted(async () => {
  await fetchBranchContact()
})

const props = defineProps({
  isCollapse: {
    type: Boolean,
    default: false
  }
})

const handleClick = (contact) => {
  if (contact.name.lowerCase() === 'live chat') {
    const openNewTab = window.open('about:blank', '_blank')
    openNewTab.location.href = contact.url
  }
}
</script>

<style lang="scss" scoped src="assets/scss/components/desktop/layouts/footer/footer.scss"></style>
